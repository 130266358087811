import style from './index.module.css'
import { NavLink, useLocation } from 'react-router-dom';
import { navLinks } from '../../utils/constants';
import { appName } from '../../utils/constants';
import gsap from 'gsap';
import useAttractHoverEffect from '../../hooks/useAttractHoverEffect';
import { resumeLink } from '../../utils/constants';


function Header() {
  const location = useLocation();
  const { pathname } = location;
  function showHoverActive(link: any) {
    if (link.to !== pathname) {
      const ele = '#dot' + link.name;
      gsap.fromTo(ele, { height: 0, width: 0 }, { height: '6px', width: '6px', duration: 0.5 })
    }
  }

  function hideHoverActive(link: any) {
    if (link.to !== pathname) {
      const ele = '#dot' + link.name;
      gsap.fromTo(ele, { height: '6px', width: '6px' }, { height: 0, width: 0, duration: 0.5 })
    }
  }
  useAttractHoverEffect('#header_logo', 0.2, 0.3);
  useAttractHoverEffect('#nav1', 0.5, 0.4);
  useAttractHoverEffect('#nav2', 0.5, 0.3);
  useAttractHoverEffect('#nav3', 0.5, 0.3);
  return (
    <section>
      <div className={`${style.header} ${style.header_font_style} flex jc-space-between`}>
        <NavLink to='/' id='header_logo'>
          {appName}
        </NavLink>
        <ul className={`flex ${style.nav_items}`}>
          {navLinks.map((link, idx) => {
            return (
              <li id={'nav' + idx} className={style.header_nav_container} key={link.id} onMouseEnter={() => showHoverActive(link)} onMouseLeave={() => hideHoverActive(link)}>
                <NavLink
                  to={link.to}
                  className={style.dot_container}
                >
                  {idx === 0 ? '' : link.name}
                  {idx ? <div id={'dot' + link.name} className={pathname === link.to ? `${style.dot_container_common} ${style.dot_container_opacity}` : `${style.dot_container_common} ${style.dot_on_hover}`}></div> : ''}
                </NavLink>
              </li>
            );
          })}
          <li id={'nav3'} className={style.header_nav_container} key={4} onMouseEnter={() => showHoverActive({ to: '/resume', name: 'resume' })} onMouseLeave={() => hideHoverActive({ to: '/resume', name: 'resume' })}>
            <a
              href={resumeLink}
              target='_blank'
              className={style.dot_container}
            >
              Resume
              <div id={'dotresume'} className={pathname === '/resume' ? `${style.dot_container_common} ${style.dot_container_opacity}` : `${style.dot_container_common} ${style.dot_on_hover}`}></div>
            </a>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Header