import AOS from "aos";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useAOS() {
  const location = useLocation();
  useEffect(() => {
    AOS.init({
      duration: 1000, // duration of animations in milliseconds
      easing: "ease-in-out", // easing function
      once: true, // whether animation should happen only once
      mirror: false, // whether elements should animate out while scrolling past them
    });
    AOS.refresh();
  }, [location]);
}

export default useAOS;
