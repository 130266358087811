import React, { useRef } from 'react';
import styles from './index.module.css';
import Book from '../../../assets/imgages/Book.png'
import Whatit1 from '../../../assets/imgages/whatit1.png'
import Whatit2 from '../../../assets/imgages/whatit2.png'
import Group1 from '../../../assets/imgages/Group1.png'
import Group2 from '../../../assets/imgages/Group2.png'
import Group3 from '../../../assets/imgages/Group3.png'
import Banner from '../../../assets/imgages/Banner.png'
import Whatit3 from '../../../assets/imgages/8b1.png'
import Group from '../../../assets/imgages/Group.png'
import Presentation from '../../../assets/new-images/TJUCourse.png'
import Thought from '../../../assets/imgages/Thought.png'
import NextCase from '../../NextCase';
import Header from '../../Header';
import globalBridgeImage from '../../../assets/new-images/Global_Bridge.png';
import SectionNavigation from '../../SectionNavigation';


const Tju: React.FC = () => {
    const topRef = useRef<HTMLDivElement>(null);
    const sectionButtionRef = useRef<HTMLDivElement>(null);

    const data = [
        {
            image: Group1,
            text: 'Chair, Undergraduate Program',
            paragraph: 'BannerWeb is the clunkiest, most not intuitive system! You have to Eog in three times before you can have access to DegreeWorks!?! And then I have to put in a student s campus key in order to get into the system. I mean, did we pay more for the worst one?',
        },
        {
            image: Group2,
            text: 'Student, graduate Program',
            paragraph: '[Course description] inìormation is also available on Banner Web, but it will be difficult for you to find this information iì you don t know the course code or course number. And most oì the time, I always found myself asking classmates.',
        },
        {
            image: Group3,
            text: 'Student, Undergraduate Program',
            paragraph: 'I use a website called Coursicle. It s way better than Banner. People use it and every school has access to it. Just type in Jefferson and classes . You ll see how people have rated a professor as positively or negatively and see the course description easily.',
        },
    ];

    const sectionRefs = [
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
        useRef<HTMLDivElement>(null),
    ];
    // const arrayToMap = ['Problem', 'Solution', 'Process', 'Research', 'Results'];
    const arrayToMap = ['Problem', 'Research', 'Table Event', 'Insights', 'Solution'];
    return (
        <section>
            {/* <Loader /> */}
            <Header />
            {/* tju page section */}
            <section className={`container-data ${styles.topContainer}`} ref={topRef}>
                <span className={styles.title}>TJU Course Reg</span>
                <div className={styles.calorie_wise_container}>
                    <div className={styles.calorie_wise_product}>Product Design</div>
                    <div className={styles.calorie_wise_product}>UXR</div>
                    <div className={styles.calorie_wise_product}>User Testing</div>
                </div>
            </section>
            <section className={styles.container}>
                <div className={styles.item} >
                    <span className={styles.itemText}>Role</span>
                    <div className={styles.line}></div>
                    <div className={styles.paragraph}>Product Designer</div>
                </div>

                <div className={styles.item} >
                    <span className={styles.itemText}>What I Did</span>
                    <div className={styles.line}></div>
                    <div className={styles.paragraph}>Internal & External Secondary Research,
                        Semi Structured Interviews, Research Artifacts, Insights Development </div>
                </div>

                <div className={styles.item} >
                    <span className={styles.itemText}>Time line</span>
                    <div className={styles.line}></div>
                    <div className={styles.paragraph}>3 Months</div>
                </div>

            </section>

            <section className={`container-data-small ${styles.main}`}>
                <a href='https://www.figma.com/proto/UevtvBFS7VnU0Evrgjxf4n/3Day-Workweek?page-id=46%3A2&node-id=107-123&node-type=frame&viewport=277%2C-336%2C0.04&t=2IRWT59cKlvN5FrT-1&scaling=contain&content-scaling=fixed&starting-point-node-id=107%3A123' className={styles.circle} target='_blank'>
                    View<br></br>Presentation
                </a>
                <div className={styles.blueDiv} data-aos="fade-up">
                    <div className={styles.imageContainer}>
                        <img src={Presentation} alt="Descriptive text" className={styles.image} />
                    </div>
                </div>
            </section>

            <section className={`container-data ${styles.containerSection3}`}>
                <div className={styles.section3LeftContainer} data-aos="fade-up">
                    <span className={styles.section3GoalText}>Goal</span>
                    <div className={styles.section3GoalDescription}>
                        The goal was to optimize and streamline the course registration process at Thomas Jefferson University, enhancing efficiency and user experience for students, faculty, and program directors.
                    </div>
                </div>

                <div className={styles.section3RightContainer} data-aos="fade-up">
                    <span className={styles.section3ToolsText}>Tools</span>
                    <div className={styles.section3ToolsContainer} >
                        <span className={styles.tag2}>MiroBoard</span>
                        <span className={styles.tag2}>Figjam</span>
                        <span className={styles.tag2}>User Interviews</span>
                        <span className={styles.tag2}>Surveys</span>
                        <span className={styles.tag2}>Table Event</span>
                        <span className={styles.tag2}>Figma</span>
                    </div>
                </div>
            </section>

            <section className={`container-data ${styles.section3Container}`}>
                <div className={styles.section3ProblemStatementContainer} ref={sectionRefs[0]} data-aos="fade-up">
                    <span className={styles.section3ProblemStatementTitle}>Problem Statement</span>
                    <div className={styles.section3ProblemStatementContent}>
                        <div className={styles.section3ProblemPoint}>
                            <div className={styles.section_circle}>•</div>
                            <div>The current state of TJU's course registration process causes confusion and frustration for students and staff.</div>
                        </div>
                        <div className={styles.section3ProblemPoint}>
                            <div className={styles.section_circle}>•</div>
                            <div>We aim to improve the process and its tools for a more efficient experience.</div>
                        </div>
                        <div className={styles.section3ProblemPoint}>
                            <div className={styles.section_circle}>•</div>
                            <div>Our objectives include:</div>
                        </div>

                        <div style={{ marginLeft: '25px' }}>
                            <div className={styles.section3ProblemPoint}>
                                <div className={styles.section_circle}>•</div>
                                <div><span className={styles.section3ProblemPointBold}>Reducing</span> staff workload.</div>
                            </div>
                            <div className={styles.section3ProblemPoint}>
                                <div className={styles.section_circle}>•</div>
                                <div><span className={styles.section3ProblemPointBold}>Enhancing</span> student resources and confidence.</div>
                            </div>
                            <div className={styles.section3ProblemPoint}>
                                <div className={styles.section_circle}>•</div>
                                <div>Improving <span className={styles.section3ProblemPointBold}>overall satisfaction</span> for all stakeholders.</div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={styles.section3ChallengeContainer} data-aos="fade-up">
                    <span className={styles.section3ChallengeTitle}>Challenge</span>
                    <div className={styles.section3ChallengeContent}>
                        <div className={styles.section3ChallengeText}>
                            How might we <span className={styles.section3ChallengeTextBold}>improve</span> TJU's course registration process and tools to reduce confusion and frustration, making the experience more <span className={styles.section3ChallengeTextBold}>efficient</span> and less <span className={styles.section3ChallengeTextBold}>burdensome</span> for all stakeholders?
                        </div>
                    </div>
                </div>
            </section>

            <section className={`container-data ${styles.section3Container}`}>
                <div className={styles.section3ProblemStatementContainer} ref={sectionRefs[1]} data-aos="fade-up">
                    <span className={styles.section3ProblemStatementTitle}>Research Objectives</span>
                    <div className={styles.section3ProblemStatementContent}>
                        <div className={styles.section3ProblemPoint}>
                            <div className={styles.section_circle}>•</div>
                            <div>To <span className={styles.section3ProblemPointBold}>understand</span> current Course Registration Process</div>
                        </div>
                        <div className={styles.section3ProblemPoint}>
                            <div className={styles.section_circle}>•</div>
                            <div>To know the <span className={styles.section3ProblemPointBold}>existing</span> communication channels and resources</div>
                        </div>
                        <div className={styles.section3ProblemPoint}>
                            <div className={styles.section_circle}>•</div>
                            <div>What are various stakeholders’ <span className={styles.section3ProblemPointBold}>roles</span> with regard to the course registration process</div>
                        </div>

                        <div className={styles.section3ProblemPoint}>
                            <div className={styles.section_circle}>•</div>
                            <div>Find <span className={styles.section3ProblemPointBold}>opportunities</span> to improve the current course registration process</div>
                        </div>
                    </div>
                </div>

                <div className={styles.section3ChallengeContainer} data-aos="fade-up">
                    <span className={styles.section3ChallengeTitle}>Research Goals</span>
                    <div className={styles.section3ChallengeContent}>
                        <div className={styles.section3ProblemPoint}>
                            <div className={styles.section_circle}>•</div>
                            <div><span className={styles.section3ProblemPointBold}>Comprehensive Understanding:</span> Deeply understand the current course registration process at Thomas Jefferson University</div>
                        </div>
                        <div className={styles.section3ProblemPoint}>
                            <div className={styles.section_circle}>•</div>
                            <div><span className={styles.section3ProblemPointBold}>Communication Channel and Resources Analysis:</span>  Examine the existing communication channels and resources used in the registration system.</div>
                        </div>
                        <div className={styles.section3ProblemPoint}>
                            <div className={styles.section_circle}>•</div>
                            <div><span className={styles.section3ProblemPointBold}>Stakeholder Roles Identification:</span>  Identify and clarify the roles of students, faculty, and program directors in the registration process.</div>
                        </div>

                        <div className={styles.section3ProblemPoint}>
                            <div className={styles.section_circle}>•</div>
                            <div><span className={styles.section3ProblemPointBold}>Process Improvement Opportunities:</span> Discover opportunities to improve the efficiency and effectiveness of the registration process to enhance the experience for all involved.</div>
                        </div>

                    </div>
                </div>
            </section>

            <section className={`container-data-small ${styles.secondry_research_section}`} >
                <div className={styles.bg_secod_search}>
                    <div className={styles.section3ProblemStatementContainer} ref={sectionRefs[2]} data-aos="fade-up">
                        <span className={styles.section3ChallengeTitle}>Secondary Research</span>
                        <div className={styles.section4Content}>
                            <a href="https://drive.google.com/file/d/1W_VMFtMgz2DLuHd8y8JOawGMuWB5eDL8/view"><div className={styles.section4Text}>
                                🔗 for University Course Catalog
                            </div></a>
                            <a href="https://drive.google.com/file/d/1W_VMFtMgz2DLuHd8y8JOawGMuWB5eDL8/view"><div className={styles.section4Text}>
                                🔗 for Graduate Course Description
                            </div></a>
                            <a href="https://drive.google.com/drive/folders/1tZWLuzFfH2o7WueG05bzfdf30jAzTC1w"><div className={styles.section4Text}>
                                🔗 for Correspondence from TJU to Students
                            </div></a>

                            <a href="https://drive.google.com/file/d/1rVcs6nI66Rq6-c1Bs-Ga3mpzi7bmpAiW/view"><div className={styles.section4Text}>
                                🔗 for TJU BannerWeb Waitlist
                            </div></a>

                        </div>
                    </div>
                    <div className={styles.section4Container} data-aos="fade-up">
                        <img src={Book} alt="Descriptive text" className={styles.book} />
                    </div>
                </div>
                <div className={`${styles.secondry_research_section_padding} ${styles.bg_secod_search}`}>
                    <div className={styles.section3ProblemStatementContainer} data-aos="fade-up">
                        <span className={styles.section4TitleText}>Semi - Structured Interviewew</span>
                        <div className={styles.section4InnerContent}>
                            <div className={styles.section4InnerText}>
                                • Undergraduate Students
                            </div>
                            <div className={styles.section4InnerText}>
                                • Registrar
                            </div>
                            <div className={styles.section4InnerText}>
                                • Academic Advising
                            </div>

                            <div className={styles.section4InnerText}>
                                • Faculty
                            </div>
                            <div className={styles.section4InnerText}>
                                • Program Directors
                            </div>
                            <div className={styles.section4InnerText}>

                                • Graduate Students
                            </div>

                        </div>
                    </div>
                    <div className={styles.section4RightInnerContainer} data-aos="fade-up">
                        <img src={Group} alt="Descriptive text" className={styles.group} />
                    </div>
                </div>
            </section>

            <section className={`container-data ${styles.thoughtsContainer}`} data-aos="fade-up" ref={sectionRefs[2]}>
                <span className={styles.section4TitleText}>Pretzel for your Thoughts</span>
            </section>

            <section className='container-data-small' data-aos="fade-up">
                <img src={Thought} alt="Descriptive text" className={styles.thought} />
            </section>

            <section className={`container-data ${styles.thoughtsParagraphContainer}`} data-aos="fade-up">
                <span className={styles.thoughtsParagraph}>We conducted a tabling event titled <span className={styles.thoughtsParagraphBold}>'Pretzel for Your Thoughts,'</span> during which we offered free pretzels in exchange for brief one-minute interviews. Our objective was to gather insights from students on campus regarding their perspectives on BannerWeb and their experiences with the admission process.</span>
            </section>

            <section className={`container-data ${styles.researchParagraphContainer}`} data-aos="fade-up" ref={sectionRefs[3]}>
                <span className={styles.section4TitleText}>Research Insights</span>
                <div className={styles.researchParagraph}>
                    <span className={styles.thoughtsParagraph}>Seamless, up-to-date integration of TJU’s course catalog/descriptions, more details and calls-to-action in a more dynamic BannerWeb/registration interface would support students independently as they research and plan their coursework.</span>
                </div>
            </section>

            <section className={`container-data ${styles.researchinsightContainerWrapper}`}>
                {data.map((item, index) => (
                    <div key={index} className={styles.researchinsightContainer} data-aos="fade-up">
                        <div className={styles.researchinsightImageContainer} >
                            <img src={item.image} alt={`Descriptive text ${index + 1}`} className={styles.researchinsightSmallImage} />
                            {index === 1 ? <div className={styles.researchinsightSmallText}>{item.text.split(' ')[0]}<br></br>{item.text.split(' ')[1]}<span style={{ visibility: 'hidden' }}>Under</span><br></br> {item.text.split(' ')[2]}</div> :
                                <div className={styles.researchinsightSmallText}>{item.text}</div>
                            }

                        </div>
                        <div className={styles.researchInsightParagraphContainer} >
                            <div className={styles.researchinsightParagraph}>
                                {item.paragraph}
                            </div>
                        </div>
                    </div>
                ))}
            </section>

            <section className={`container-data ${styles.researchParagraphContainer}`} data-aos="fade-up">
                <span className={styles.section4TitleText}>Insight 1</span>
                <div className={styles.researchParagraph}>
                    <span className={styles.insightParagraph}>A <span className={styles.insightParagraphBold}>students</span> registration experience varies wildly based on their <span className={styles.insightParagraphBold}>program</span> due to the <span className={styles.insightParagraphBold}>communication</span> styles & their individual <span className={styles.insightParagraphBold}>relationships</span> with <span className={styles.insightParagraphBold}>faculty</span> and <span className={styles.insightParagraphBold}>fellow</span> students.
                        <br></br>
                        <br></br>
                        <span className={styles.insightParagraphBold}></span>Consistent, strategic communications across all 160+ programs regarding schedules, resources and options would ensure all <span className={styles.insightParagraphBold}>students</span> feel informed and <span className={styles.insightParagraphBold}>confident</span> to move forward in selecting their <span className={styles.insightParagraphBold}>classes.</span></span>
                </div>
            </section>

            <section className={styles.whatItContainer} data-aos="fade-up">
                <img alt='What it' src={Whatit1} className={styles.thought} />
            </section>

            <section className={styles.whatIt2Container} data-aos="fade-up">
                <img alt='What it 2' src={Whatit2} className={styles.thought} />
            </section>

            <section className={`container-data ${styles.researchParagraphContainer}`} data-aos="fade-up">
                <span className={styles.section4TitleText}>Insight 2</span>
                <div className={styles.researchParagraph}>
                    <span className={styles.insightParagraph}>
                        <span className={styles.insightParagraphBold}>Academic Advising</span> connects TJU students with <span className={styles.insightParagraphBold}>integrated support services, course selection</span> and <span className={styles.insightParagraphBold}>registration guidance</span> and tools to improve their <span className={styles.insightParagraphBold}>holistic</span> experience.
                        <br></br>
                        <br></br>
                        Jefferson should <span className={styles.insightParagraphBold}>invest in</span> and <span className={styles.insightParagraphBold}>scale</span> its effective and vital <span className={styles.insightParagraphBold}>Advising services</span> to <span className={styles.insightParagraphBold}>include graduate students</span> so all Jefferson students can access the supports, tools and resources TJU offers.
                    </span>
                </div>
            </section>
            <section className={styles.whatIt3Container} data-aos="fade-up">
                <img alt='What it 2' src={Whatit3} className={styles.thought} />
            </section>

            <section className={styles.insight3Main} ref={sectionRefs[4]}>
                <div className={`container-data ${styles.insight3Container1}`}>
                    <div data-aos="fade-up" className={styles.insight3Container}>
                        <span className={styles.section3ToolsText}>Insight 3</span>
                        <div className={styles.insight3ImageContainer}>
                            <img alt='What it 2' src={Banner} className={styles.thought} />
                        </div>
                    </div>
                </div>
            </section>
            <SectionNavigation sectionButtionRef={sectionButtionRef} arrayToMap={arrayToMap} sectionRefs={sectionRefs} topRef={topRef} />
            <NextCase sectionButtionRef={sectionButtionRef} caseName='Globale_Bridge' redirectUrl="/" imageUrl={globalBridgeImage} />
        </section>
    );
};

export default Tju;
