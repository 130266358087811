import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './index.css'
import useAttractHoverEffect from '../../hooks/useAttractHoverEffect';
import { useNavigate } from 'react-router-dom';

const AnimatedButton = ({ linkTo, openModal, name, id, mouseEnterTop, mouseEnterToScale, linkClassName, circleClassName, onClick, circleColor, children }: any) => {
  const buttonRef = useRef(null);
  const circleRef = useRef(null);
  const hoverSize = useRef<any>(null);
  const areaSize = useRef<any>(null);
  const navigate = useNavigate();
  const redirectTo = ()=> {
    if(linkTo) {
      navigate(linkTo);
    }
    if(onClick) {
      onClick()
    }
  }
  if(id === 'foote_email' || id === 'footer_contact') {
    hoverSize.current = 0.4;
    areaSize.current = 0.3;
  }
  // if(id !== 'get_in_touch') {
    useAttractHoverEffect('#' + id, hoverSize.current, areaSize.current);
    useAttractHoverEffect('#about_me_btn_name' + id, hoverSize.current, areaSize.current)
  // }
  // Function to handle mouse enter
  const handleMouseEnter = () => {
    if (openModal) return;
    gsap.fromTo(circleRef.current,
      {
        top: mouseEnterTop,
        scale: 1.1
      },
      {
        scale: mouseEnterToScale, // Make the circle large enough to cover the entire button
        top: mouseEnterTop,
        backgroundColor: circleColor ? circleColor : '#3C52E6', // Change to the desired hover color
        duration: 0.6, // Duration of the animation
        ease: 'power2.in'
      }
    );
  };

  // Function to handle mouse leave
  const handleMouseLeave = () => {
    if (openModal) return;
    gsap.to(circleRef.current, {
      scale: 1, // Shrink the circle back to its original size
      top: '-100%',
      duration: 0.8, // Duration of the animation
      ease: 'power2.out',
      backgroundColor: circleColor ? circleColor : '#3C52E6',
    });
  };

  useEffect(() => {
    handleMouseLeave();
  }, [openModal])

  return (
    <button
      onClick={() => redirectTo()}
      ref={buttonRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={linkClassName}
      id={id}
    >
      <div ref={circleRef} className={circleClassName}></div>
      <div className='about_me_btn_name' id={'about_me_btn_name' + id}>
        {name ? name : children}
      </div>
    </button>
  );
};

export default AnimatedButton;
