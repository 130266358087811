import React, { useEffect, useRef } from 'react';
import styles from './index.module.css';
import work1 from '../../../assets/new-images/Global_Bridge.png';
import work2 from '../../../assets/new-images/CalorieWise.png';
import work3 from '../../../assets/new-images/TJU_Banner.png';
import work4 from '../../../assets/new-images/Hair_Food.png';
import work5 from '../../../assets/imgages/work5.png';
import work6 from '../../../assets/imgages/work6.png';
import Footer from '../../Footer';
import Header from '../../Header';
import gsap from 'gsap';
import { useNavigate } from 'react-router-dom';

const Work: React.FC = () => {
  const navigate = useNavigate();
  const items = [
    {
      id: 1,
      imageSrc: work1,
      title: "Global Bridge",
      description: "UX Thesis project",
      color: '#FFF4D4',
      URL: "https://www.canva.com/design/DAGD21pQ0o4/krZZFQKn1jBvZOe5f3Sc7Q/view?utm_content=DAGD21pQ0o4&utm_campaign=designshare&utm_medium=link&utm_source=editor",
      isExternal: true,
    },
    {
      id: 2,
      imageSrc: work2,
      title: "Calorie Wise",
      description: "Meal tracking App",
      color: '#FFD7BA',
      URL: "/calorie-wise",
    },
    {
      id: 3,
      imageSrc: work3,
      title: "TJU COurse Reg",
      description: "Research project",
      URL: "/improving-the-course-registration-experience",
      color: '#E6EDFB',
    },
    {
      id: 4,
      imageSrc: work4,
      title: "Hair food",
      description: "Academic project",
      color: '#FBF5F1',
      URL: "/hair-food"
    },
    {
      id: 5,
      imageSrc: work5,
      title: "Analytics",
      description: "wanderer",
      color: '#FFE1D9',
      isExternal: true,
      URL: 'https://www.behance.net/gallery/125024717/Analytics'
    },
    {
      id: 6,
      imageSrc: work6,
      title: "wanderer",
      description: "behance project",
      color: '#7D8DFF',
      isExternal: true,
      URL: 'https://www.behance.net/gallery/93840239/Wanderer'
    },
  ];

  const viewBtnRefTl = useRef<any>(null);

  viewBtnRefTl.current = gsap.timeline({ ease: "SlowMo.easeOut" });

  const redirectTo = (item: any) => {
    if (item.isExternal) {
      window.open(item.URL);
    } else {
      navigate(item.URL)
    }
  }

  useEffect(() => {
    let tl = gsap.timeline({ ease: "SlowMo.easeOut" });
    tl.fromTo('#hide', { opacity: 0 }, {
      opacity: 1, duration: 0.5, onComplete: () => {
        tl.to('#create', { y: '0%', duration: 0.7 });
      }
    });
  }, []);
  const cursorRefArray = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null)
  ];

  const spanRefArray = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null)
  ];

  const editCursor = (e: any, index: number) => {
    const cursorRef = cursorRefArray[index];
    const spanViewRef = spanRefArray[index];
    if (cursorRef.current && spanViewRef.current) {
      cursorRef.current.style.display = 'flex';
      spanViewRef.current.style.display = 'block';

      const { clientX: x, clientY: y } = e;

      // Animate the cursor size and position using GSAP
      gsap.to(cursorRef.current, {
        duration: 0.3,
        width: 100,
        height: 100,
        left: x - 50,
        top: y - 50,
        ease: 'power2.out',
      });
    }
  };

  const removeCursor = (e: any, index: number) => {
    const cursorRef = cursorRefArray[index];
    const spanViewRef = spanRefArray[index];
    if (cursorRef.current && spanViewRef.current) {
      // Animate the cursor size reduction using GSAP
      gsap.to(cursorRef.current, {
        duration: 0.3,
        width: 0,
        height: 0,
        ease: 'power2.out',
        onComplete: () => {
          cursorRef.current.style.display = 'none';
          spanViewRef.current.style.display = 'none';
        },
      });
    }
  };

  return (
    <section>
      {/* <Loader /> */}
      <Header />
      <div id='hide'>
        <div className={`${styles.title} hide_overflow`}>
          <div className='hide_overflow'><span id='create'>Crafting better</span></div>
          <div className='hide_overflow'><span id='create'>digital experience</span></div>
        </div>
      </div>
      <div className={styles.work_container_bg}>
        <div className={styles.gridViewContainer}>
          {items.map((item, idx) => (
            <div id={'container' + item.id} key={item.id} className={(idx % 2 === 0) ? styles.gridViewItemEven : styles.gridViewItemOdd}>
              <div
                onMouseMove={(e) => { editCursor(e, idx) }}
                onMouseLeave={(e) => { removeCursor(e, idx) }}
                onClick={() => redirectTo(item)}
              >
                <div data-aos="fade-up" className={styles.gridViewImageContainer} style={{ backgroundColor: item.color }}>
                  <div ref={cursorRefArray[idx]} className='view_cursor'> <span ref={spanRefArray[idx]}>View</span> </div>
                  <img src={item.imageSrc} alt={`display ${item.id}`} className={styles.gridViewImage} />
                </div>
                <div data-aos="fade-up">
                  <h2 className={styles.gridViewTitle}>{item.title}</h2>
                  <hr className={styles.gridViewSeparator} />
                  <p className={styles.gridViewDescription}>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Work;
