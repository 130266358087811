import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import styles from './index.module.css'
import { useLocation } from 'react-router-dom';
const pageURLs = [
  {
    path: '/',
    name: 'Home'
  },
  {
    path: '/work',
    name: 'Work'
  },
  {
    path: '/about',
    name: 'About'
  },
  {
    path: '/calorie-wise',
    name: 'Calorie Wise'
  },
  {
    path: '/improving-the-course-registration-experience',
    name: 'Improving the Course Registration Experience'
  },
  {
    path: '/hair-food',
    name: 'Hair Food'
  },
  {
    path: '/global-bridge',
    name: 'Global Bridge'
  }
]

const Loader = () => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const loaderRef = useRef(null);
  const pageContainerRef = useRef(null);
  const pageNameRef = useRef(null);
  const location = useLocation();
  const { pathname } = location;
  const currentPageName = pageURLs.find(pageURL => pageURL.path === pathname)?.name;
  const scrollTo = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  scrollTo();

  useEffect(() => {

    window.addEventListener('load', scrollTo);
    // This will run only on the first render
    const isVisited = sessionStorage.getItem('isVisited');
    if (isVisited) {
      setIsFirstLoad(false);
    } else {
      sessionStorage.setItem('isVisited', 'true');
    }
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('load', scrollTo);
    };
  }, []);

  useEffect(() => {
    scrollTo();
    const tl = gsap.timeline({ defaults: { ease: 'power1.inOut' } });
    tl.to(loaderRef.current, {
      top: 0, height: '100%', duration: 0.8, onComplete: () => {
        tl.to(pageNameRef.current, { opacity: 1, duration: 0.5 });
        tl.to(loaderRef.current, { height: '0%', delay: 1, duration: 0.5 });
        tl.to(pageNameRef.current, { opacity: 0, duration: -0.2 });
        tl.to(pageContainerRef.current, { height: '0%', duration: 0.5 })
      }
    });
  }, [pathname]);

  function GetLoaderData() {
    if (isFirstLoad) {
      return (
        <div className={styles.container}>
          <div id="word_1" className={styles.word}>Hi</div>
          <div id="word_2" className={styles.word}>Holá</div>
          <div id="word_3" className={styles.word}>Bonjour</div>
          <div id="word_4" className={styles.word}>Hallo</div>
          <div id="word_5" className={styles.word}>Nǐ hǎo</div>
          <div id="word_6" className={styles.word}>Ciao</div>
          <div id="word_7" className={styles.word}>नमस्ते</div>
        </div>
      );
    } else {
      return <>{currentPageName ? currentPageName : '404 Page not found'}</>;
    }
  }

  return (<div ref={pageContainerRef} className={styles.loader_container}>
    <div ref={loaderRef} className={styles.loader}>
      <div ref={pageNameRef} className={styles.loader_page_name}>
        <GetLoaderData />
      </div>
    </div>
  </div>
  );
};

export default Loader;
