import React, { useEffect, useRef } from 'react';
import styles from './index.module.css'
import FooterInfo from '../Footer/FooterInfo';
import { useNavigate } from 'react-router-dom';
import AnimatedButton from '../AnimatedButton';
import gsap from 'gsap';

function NextCase({ caseName, redirectUrl, imageUrl, sectionButtionRef }: any) {
  const navigate = useNavigate();

  const viewBtnRefTl = useRef<any>(null);
  const observedDivRef = useRef<any>(null);
  const imageRef = useRef(null);
  const cursorRef = useRef<any>(null);
  const spanViewRef = useRef<any>(null);
  viewBtnRefTl.current = gsap.timeline({ ease: "SlowMo.easeOut" });

  const editCursor = (e: any) => {
    if (cursorRef.current && spanViewRef.current) {
      cursorRef.current.style.display = 'flex';
      spanViewRef.current.style.display = 'block';

      const { clientX: x, clientY: y } = e;

      // Animate the cursor size and position using GSAP
      gsap.to(cursorRef.current, {
        duration: 0.3,
        width: 100,
        height: 100,
        left: x - 50,
        top: y - 50,
        ease: 'power2.out',
      });
      gsap.to(spanViewRef.current, { fontSize: '18px', duration: 0.5 });
    }
  };

  const removeCursor = () => {
    if (cursorRef.current && spanViewRef.current) {
      // Animate the cursor removeCursorsize reduction using GSAP
      gsap.to(spanViewRef.current, { fontSize: '0px', duration: 0.5 });
      gsap.to(cursorRef.current, {
        duration: 0.3,
        width: 0,
        height: 0,
        ease: 'power2.out',
        onComplete: () => {
          cursorRef.current.style.display = 'none';
          spanViewRef.current.style.display = 'none';
        },
      });
    }
  };

  const displayImage = () => {
    viewBtnRefTl.current.fromTo(imageRef.current, { height: '80px', y: '0%' }, { y: '-0%', height: '180px', duration: 0.5, delay: 0.2 })
  }
  const hideImage = () => {
    removeCursor();
    viewBtnRefTl.current.to(imageRef.current, { height: '80px', y: '0%', duration: 0.5 })
  }

  const handleMouseEnter = (event: any) => {
    displayImage();
  };

  const handleMouseLeave = (event: any) => {
    hideImage();
  };
  const redirectTo = (URL: any) => {
    navigate(URL);
  }
  useEffect(() => {
    // Create a new IntersectionObserver instance
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // If the observed div is in the viewport, hide the target div
          if (sectionButtionRef?.current) {
            gsap.to(sectionButtionRef.current, { opacity: 0, duration: 0.5 })
          }
        } else {
          // If the observed div is out of the viewport, show the target div
          if (sectionButtionRef?.current) {
            gsap.to(sectionButtionRef.current, { opacity: 1, duration: 0.5 })
          }
        }
      });
    }, {
      root: null, // Use the viewport as the root
      threshold: 0.1 // Trigger when 10% of the observed element is in view
    });

    // Start observing the observed div
    observer.observe(observedDivRef.current);
  }, [])
  return (
    <section ref={observedDivRef} className={styles.next_case_container}>
      <div className={`container-data ${styles.next_case_data_container}`}>
        <div
          className={styles.circle_position}
          onMouseEnter={(e) => handleMouseEnter(e)}
          onMouseLeave={(e) => handleMouseLeave(e)}
          onMouseMove={(e) => { editCursor(e) }}
          onClick={() => redirectTo(redirectUrl)}
        >
          <div className={styles.next_case_next_title}>
            Next Case
          </div>
          <div className={styles.next_case_case_name}>
            {caseName}
          </div>

          <div className={styles.next_case_container1}>
            <div className={styles.next_case_container_div_bg} >
              <div className={styles.image_height_div} ref={imageRef}>
                <img alt='Demo' src={imageUrl}></img>
              </div>
            </div>
            <div className={styles.next_case_container_div_border}></div>
          </div>
          <div ref={cursorRef} className='view_cursor'> <span ref={spanViewRef}>View</span> </div>
        </div>

        {/* All Work */}

        <div className={styles.next_case_all_work_container}>
          <AnimatedButton linkTo='/work' id="all-work" mouseEnterTop="130%" mouseEnterToScale={5} linkClassName='about_common_class footer_contact_info_btn_border next_case_font_style next_case_height_width_style' circleClassName='about_common_circle_class next_case_circle next_case_all_work_btn_circle next_case_height_width_style' >
            <span><span className={styles.next_case_all_work_title}>All Work &nbsp; </span> <span className={styles.next_case_work_count}>(6)</span></span>
          </AnimatedButton>
        </div>

        <div className={styles.next_case_footer_info}>
          <FooterInfo />
        </div>
      </div>
    </section>
  )
}

export default NextCase