import { useEffect } from "react";
import gsap from "gsap";
class HoverButton {
  constructor(el, hoverSize, areaSize) {
    this.el = el;
    this.hover = false;
    this.hoverSize = hoverSize ? hoverSize : 0.5;
    this.areaSize = areaSize ? areaSize : 0.3;
    this.calculatePosition();
    this.attachEventsListener();
  }

  attachEventsListener() {
    window.addEventListener("mousemove", (e) => this.onMouseMove(e));
    window.addEventListener("resize", (e) => this.calculatePosition(e));
  }

  calculatePosition() {
    if (this.el) {
      gsap.set(this.el, {
        x: 0,
        y: 0,
        scale: 1,
      });
      const box = this?.el?.getBoundingClientRect();
      if (box) {
        this.x = box.left + box.width * 0.5;
        this.y = box.top + box.height * 0.5;
        this.width = box.width;
        this.height = box.height;
      }
    }
  }

  onMouseMove(e) {
    this.calculatePosition(e);
    let hover = false;
    let hoverArea = this.hover ? this.hoverSize : this.areaSize;
    let x = e.clientX - this.x;
    let y = e.clientY - this.y;
    let distance = Math.sqrt(x * x + y * y);
    if (distance < this.width * hoverArea) {
      hover = true;
      if (!this.hover) {
        this.hover = true;
      }
      this.onHover(e.clientX, e.clientY);
    }

    if (!hover && this.hover) {
      this.onLeave();
      this.hover = false;
    }
  }

  onHover(x, y) {
    if (this.el) {
      gsap.to(this.el, {
        x: (x - this.x) * this.areaSize,
        y: (y - this.y) * this.areaSize,
        ease: "power2.out",
        duration: 0.5,
      });
      this.el.style.zIndex = 10;
    }
  }
  onLeave() {
    if (this.el) {
      gsap.to(this.el, {
        x: 0,
        y: 0,
        ease: "power2.out",
        duration: 0.5,
      });
      this.el.style.zIndex = 1;
    }
  }
}

function useAttractHoverEffect(element, hoverSize, areaSize) {
  useEffect(() => {
    if(element === '#get_in_touch' || element === '#about_me_btn_nameget_in_touch'){
      // do nothing
    } else { 
    const btn1 = document.querySelector(element);
    new HoverButton(btn1, hoverSize, areaSize);}
  });
}

export default useAttractHoverEffect;
