import React, { useRef } from 'react';
import styles from './index.module.css';
import Calwise from '../../../assets/new-images/CalorieWiseHero.png';
import Solutionimpact from '../../../assets/imgages/Solutionimpact.png'
import Groupp from '../../../assets/imgages/Groupp.png'
import Double from '../../../assets/imgages/Double.png'
import Hooked from '../../../assets/imgages/Hooked.png'
import hookedModal from '../../../assets/imgages/hookedModal.png'
import HookedM from '../../../assets/imgages/HookedM.png'
import UsabilityTesting from '../../../assets/imgages/UsabilityTesting.png'
import usability from '../../../assets/new-images/dhosa_image.png'
import FakeFrontDoor from '../../../assets/imgages/FakeFrontDoor.png'
import Mechanicalturk from '../../../assets/imgages/Mechanicalturk.png'
import circleGroupImage from '../../../assets/imgages/circleGroupImage.png'
import Phone from '../../../assets/imgages/Phone.png'
import Phone1 from '../../../assets/imgages/Phone1.png'
import Phone2 from '../../../assets/imgages/Phone2.png'
import NextCase from '../../NextCase';
import Header from '../../Header';
import HairomaMainPNG from '../../../assets/new-images/Hair_Food.png';
import SectionNavigation from '../../SectionNavigation';
import AnimatedButton from '../../AnimatedButton';


const CalorieWise: React.FC = () => {
  const topRef = useRef<HTMLDivElement>(null);
  const sectionButtionRef = useRef<HTMLDivElement>(null);

  const sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  const arrayToMap = ['Problem', 'Solution', 'Process', 'Research', 'Results', 'Takeways'];

  function redirectTo() {
    window.open('https://miro.com/app/board/uXjVMrrrqTQ=/', '_blank')
  }

  return (
    <>
      {/* <Loader /> */}
      <Header />
      {/* Section one done start */}
      <section ref={topRef} className={`container-data ${styles.topContainer}`}>
        <span className={styles.title}>Calorie Wise</span>
        <div className={styles.calorie_wise_container}>
          <div className={styles.calorie_wise_product}>Product Design</div>
          <div className={styles.calorie_wise_product}>UXR</div>
          <div className={styles.calorie_wise_product}>User Testing</div>
        </div>
      </section>
      <section className={`container-data ${styles.container}`}>
        <div className={styles.item} >
          <span className={styles.itemText}>Role</span>
          <div className={styles.line}></div>
          <div className={styles.paragraph}>Product Designer</div>
        </div>

        <div className={styles.item} >
          <span className={styles.itemText}>What I Did</span>
          <div className={styles.line}></div>
          <div className={styles.paragraph}>Recruiting Users, User Interviews, UXR,
            UX Design, Visual identity, UI Design, Usability Testing</div>
        </div>

        <div className={styles.item} >
          <span className={styles.itemText}>Timeline</span>
          <div className={styles.line}></div>
          <div className={styles.paragraph}>3 Months</div>
        </div>
      </section>
      {/* Section one end here */}

      {/* Section 2 pending */}
      <section className={`container-data-small ${styles.main}`}>
        <a className={styles.circle} target='_blank' href='https://www.figma.com/proto/Dmasjb64rlFBRR93y5J6fP/My-Portfolio?page-id=952%3A2&node-id=952-4&node-type=frame&viewport=711%2C272%2C0.02&t=ZG48kodSLhqLgJS5-1&scaling=contain&content-scaling=fixed'>
          View<br></br>Presentation
        </a>
        <div className={styles.blueDiv} data-aos="fade-up">
          <div className={styles.imageContainer}>
            <img src={Calwise} alt="Descriptive text" className={styles.image} />
          </div>
        </div>
      </section>

      {/* Section 3 done */}

      <section className={`container-data ${styles.containerSection3}`} data-aos="fade-up">
        <div className={styles.section3LeftContainer}>
          <span className={styles.section3GoalText}>Goal</span>
          <div className={styles.section3GoalDescription}>
            Designing a habit-forming meditation app based on the hooked model of <a className={styles.link} href="https://www.linkedin.com/in/ACoAAAAHyQEBDr9figMsc4ImSK0wgU_97M8PmMU">Nir Eyal's</a> book - Hooked: How to Build Habit-Forming Products, reflecting spatial, emotional, informational, and communicative issues
          </div>
        </div>

        <div className={styles.section3RightContainer}>
          <span className={styles.section3ToolsText}>Tools</span>
          <div className={styles.section3ToolsContainer}>
            <span className={styles.calorie_wise_product}>Usability Testing</span>
            <span className={styles.calorie_wise_product}>Figjam</span>
            <span className={styles.calorie_wise_product}>User Interviews</span>
          </div>
          <div className={styles.section3ToolsContainer}>
            <span className={styles.calorie_wise_product}>Pretotyping</span>
            <span className={styles.calorie_wise_product}>Figma</span>
            <span className={styles.calorie_wise_product}>MiroBoard</span>
          </div>
        </div>
      </section>

      <section className={`container-data ${styles.section3Container}`}>
        <div ref={sectionRefs[0]} className={styles.section3ProblemStatementContainer} data-aos="fade-up">
          <span className={styles.section3ProblemStatementTitle}>Problem Statement</span>
          <div className={styles.section3ProblemStatementContent}>
            <div className={styles.section3ProblemPoint}>
              <div className={styles.section_circle1}>•</div>
              <div><span className={styles.section3ProblemPointBold}>Track Calories:</span> Provide an intuitive and user-friendly interface for consistently logging daily calorie intake.</div>
            </div>
            <div className={styles.section3ProblemPoint}>
              <div className={styles.section_circle1}>•</div>
              <div><span className={styles.section3ProblemPointBold}>Foster Motivation:</span> Utilize both intrinsic and extrinsic motivational strategies to help users develop and maintain the habit of tracking their food intake.</div>
            </div>
            <div className={styles.section3ProblemPoint}>
              <div className={styles.section_circle1}>•</div>
              <div><span className={styles.section3ProblemPointBold}>Reward System:</span> Implement a rewarding mechanism that aligns with users' health goals, making the process enjoyable and habit-forming.</div>
            </div>
          </div>
        </div>

        <div className={styles.section3ChallengeContainer} data-aos="fade-up">
          <span className={styles.section3ProblemStatementTitle}>Challenge</span>
          <div className={styles.section3ChallengeContent}>
            <div className={styles.section3ChallengeText}>
              How might we make tracking calorie intake enjoyable and motivating, helping users improve their eating habits, enhance their physical health, and achieve their weight goals?
            </div>
          </div>
        </div>
      </section>

      <section className={`container-data ${styles.section4Container}`} data-aos="fade-up">
        <div ref={sectionRefs[1]} className={styles.section4SolutionContainer}>
          <span className={styles.section4SolutionTitle}>Solution and Impact</span>
          <div className={styles.section4SolutionContent}>
            <div className={styles.section4SolutionText}>
              <span className={styles.section4SolutionTextBold}>Calorie Wise:</span> An innovative calorie-tracking app where users can scan their meals to view calorie and nutrient values while earning points. These points can be redeemed for cuisine options aligned with their weight goals.
            </div>
            <div className={styles.section4ImpactText}>
              Designed with the principles of <span className={styles.section4SolutionTextBold}>'The Hooked Model,'</span> the app incorporates Trigger, Action, Variable Reward, and Investment stages to foster habit formation. Consistent food logging is rewarded with timely incentives.
            </div>
          </div>
        </div>

        <div className={styles.section4ImageContainer}>
          <img src={Solutionimpact} alt="Description" className={styles.section4Image} />
        </div>
      </section>


      {/* Section 4 done end */}

      {/* Section 5 done  */}

      <section className={`container-data-small ${styles.section5Container}`}>
        <div className={styles.section5ContentContainer}>
          <div data-aos="fade-up">
            <img src={Phone} className={styles.section5Image} alt='' />
          </div>
          <div className={styles.section5TextContainer} data-aos="fade-up">
            <div className={styles.section5DesignOutcomesTitle} data-aos="fade-up">Design outcomes</div>
            <div className={styles.section5HairomaTitle}>Log Food</div>
            <div className={styles.section5TextContent}>
              <div className={styles.section5Text}>
                Users can effortlessly log their food by either scanning the item or manually entering the food name. This flexible approach allows for quick and accurate tracking of meals, making it easier to stay on top of dietary goals.
              </div>
            </div>
          </div>
        </div>
        <div className={styles.section5ContentContainer}>
          <div data-aos="fade-up">
            <img src={Phone1} alt="Description of" className={styles.section5Image} />
          </div>
          <div className={styles.section5TextContainer} data-aos="fade-up">
            <span className={styles.section5ChooseScentTitle}>Unlocking Recipes</span>
            <div className={styles.section5TextContent}>
              <div className={styles.section5Text}>
                Users can unlock a surprise recipe from their chosen cuisine using points earned through food logging. The recipe is revealed based on their calorie goal, adding a fun and personalized touch to their culinary journey.
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.paddign_bottom} ${styles.section5ContentContainer}`}>
          <div data-aos="fade-up">
            <img src={Phone2} alt="Description of" className={styles.section5Image} />
          </div>
          <div className={styles.section5TextContainer} data-aos="fade-up">
            <span className={styles.section5CheckoutTitle}>View stats</span>
            <div className={styles.section5TextContent}>
              <div className={styles.section5Text}>
                Users earn points by registering their meal intake. They can then use these points to redeem a random recipe from the cuisine of their choice. This approach encourages users to explore new recipes while staying on track with their dietary goals.
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 5 done end */}

      <section className={`container-data-small ${styles.sectionDesignContainer}`} data-aos="fade-up">
        <div ref={sectionRefs[2]} className={styles.sectionDesignImageContainer}>
          <img src={Double} alt="Description of" className={styles.section4Image} />
        </div>
        <div className={`${styles.section_padding_left} ${styles.section4SolutionContainer}`}>
          <span className={styles.sectionDesignTitle}>Design Process</span>
          <div className={styles.sectionDesginContent}>
            <div className={styles.sectionDesignText}>
              I applied the Double Diamond Method and the 5 Elements of UX Design to create a product that fosters lasting habits, drawing on insights from the Hooked Model. After developing the prototype, I conducted usability testing and pretotyping to validate its effectiveness.
            </div>
          </div>
        </div>
      </section>

      <section className={`container-data ${styles.section_margin_top} ${styles.sectionDesignContainer}`} data-aos="fade-up">
        <div className={styles.section4SolutionContainer}>
          <span ref={sectionRefs[3]} className={styles.sectionHookedTitle}>Research and Ideation</span>
          <div className={`${styles.sectionHookedContentWidth} ${styles.sectionHookedContent}`}>
            <div className={styles.sectionHookedText}>
              Key pain points identified were limited meal prep time, emotional impact on food choices, and lack of healthy cooking info. These insights guided the ideation process, resulting in a storyboard and feature list using the KANO card method. I prioritized features with the MoSCoW methodology, establishing a solid project foundation
            </div>
            <AnimatedButton onClick={redirectTo} name='View Miroboard' id='mira_board_0' mouseEnterTop="130%" mouseEnterToScale={5} linkClassName='about_common_class miraboard_height_width_style view_mira_board_border view_mira_board_name_style' circleClassName='about_common_circle_class footer_contact_info_btn_email_circle miraboard_height_width_style' />
          </div>
        </div>
        <div className={`${styles.sectionHookedImageContainer}`}>
          <img src={Hooked} alt="Description of" className={styles.section4Image1} />
        </div>
      </section>
      {/* //pending below section */}

      <section className={`container-data ${styles.section_margin_top} ${styles.sectionDesignContainer}`}>
        <div className={styles.section4SolutionContainer} data-aos="fade-up">
          <span className={styles.sectionHookedTitle}>Pretotyping Experiments</span>
          <div className={`${styles.sectionHookedContentWidth} ${styles.sectionHookedContent}`}>
            <div className={styles.sectionHookedText}>
              To validate product desirability, I used pretotyping experiments inspired by Alberto Savoia's 'The Right It.' I first identified critical and uncertain assumptions with the 'Risky Assumption Matrix,' focusing on those that could jeopardize the product's success. Then, I created hypotheses to test their validity.
            </div>
            <AnimatedButton onClick={redirectTo} name='View Miroboard' id='mira_board_1' mouseEnterTop="130%" mouseEnterToScale={5} linkClassName='about_common_class  miraboard_height_width_style view_mira_board_border view_mira_board_name_style' circleClassName='about_common_circle_class footer_contact_info_btn_email_circle miraboard_height_width_style' />
          </div>
        </div>
        <div className={styles.sectionHookedImageContainer} data-aos="fade-up">
          <img src={hookedModal} alt="Description of" className={styles.section4Image} />
        </div>
      </section>

      <section className={`container-data ${styles.sectionDesignContainer}`}>
        <div className={styles.section4SolutionContainer} data-aos="fade-up">
          <span className={styles.sectionHookedTitle}>Mechanical turk</span>
          <div className={styles.fakefrontMaineContent}>
            <div className={styles.fakefrontGrid3}>
              <div className={styles.fakefrontBox}>
                <div className={styles.fakefrontContent}>26</div>
                <div className={styles.fakefrontDescription}>Total forms sent</div>
              </div>
              <div className={styles.fakefrontBox}>
                <div className={styles.fakefrontContent}>65%</div>
                <div className={styles.fakefrontDescription}>Users Interested</div>
              </div>
              <div className={styles.fakefrontBox}>
                <div className={styles.fakefrontContent}>17%</div>
                <div className={styles.fakefrontDescription}>Total sign ups</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionHookedImageContainer} data-aos="fade-up">
          <img src={Mechanicalturk} alt="Demo" className={styles.section4Image} />
        </div>
      </section>

      <section className={`container-data ${styles.section_margin_top} ${styles.sectionDesignContainer}`}>
        <div className={styles.section4SolutionContainer} data-aos="fade-up">
          <span className={styles.sectionHookedTitle}>Fake front door</span>
          <div className={styles.fakefrontMaineContent2}>
            <div className={styles.fakefrontGrid3}>
              <div className={styles.fakefrontBox}>
                <div className={styles.fakefrontContent}>1.1K</div>
                <div className={styles.fakefrontDescription}>Total Impressions</div>
              </div>
              <div className={styles.fakefrontBox}>
                <div className={styles.fakefrontContent}>220</div>
                <div className={styles.fakefrontDescription}>Unique  Visitors</div>
              </div>
              <div className={styles.fakefrontBox}>
                <div className={styles.fakefrontContent}>26</div>
                <div className={styles.fakefrontDescription}>Skin in the game</div>
              </div>
              <div className={styles.fakefrontBox}>
                <div className={styles.fakefrontContent}>11.8%</div>
                <div className={styles.fakefrontDescription}>Total sign ups</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionHookedImageContainer} data-aos="fade-up">
          <img src={FakeFrontDoor} alt="Demo" className={styles.section4Image} />
        </div>
      </section>

      <section className={`container-data ${styles.section_margin_top} ${styles.sectionDesignContainer}`}>
        <div ref={sectionRefs[4]} className={styles.screenUiContainer} data-aos="fade-up">
          <span className={styles.sectionHookedTitle}>Insight 1</span>
          <div className={styles.screenUiContent}>
            <div className={styles.sectionHookedText}>
              The pretotyping phase using Mechanical Turk provided key insights into user interest and engagement. Initially, users showed moderate interest, but this declined over time, along with their energy levels. While the concept initially engaged users, sustaining their interest proved challenging, as their experience became less positive as time went on. These findings highlight the need for improvements to maintain consistent user engagement.
            </div>
          </div>
          <AnimatedButton onClick={() => { window.open('https://docs.google.com/spreadsheets/d/1lk7piWKni26q1PgvR1N7YJSO81ufL4Jq/edit?gid=552399169#gid=552399169', '_blank') }} name='View Meal Log' id='mira_board_0' mouseEnterTop="130%" mouseEnterToScale={5} linkClassName='about_common_class miraboard_height_width_style view_mira_board_border view_mira_board_name_style' circleClassName='about_common_circle_class footer_contact_info_btn_email_circle miraboard_height_width_style' />
        </div>
        <div className={styles.sectionHookedImageContainer} data-aos="fade-up">
          <img src={HookedM} alt="Demo" className={styles.section4Image1} />
        </div>
      </section>

      <section className={`container-data ${styles.section_margin_top} ${styles.sectionDesignContainer}`}>
        <div className={styles.section4SolutionContainer} data-aos="fade-up">
          <span className={styles.sectionHookedTitle}>Insight 2</span>
          <div className={styles.insight2Container}>
            {/* <img src={insight2} alt="Demo" className={styles.inisight2Image1} /> */}
            <div className={styles.inside_container}>
              <div className={styles.container_number}>6</div>
              <div className={styles.container_participant}>No of Participants</div>
            </div>
            <div className={styles.male_female_container}>
              <div className={styles.inside_container} style={{ width: "123px" }}>
                <div className={styles.container_number}>4</div>
                <div className={styles.container_participant}>No of Females</div>
              </div>
              <div className={styles.container_number} style={{ margin: "0 56px" }}>&gt;</div>
              <div className={styles.inside_container} style={{ width: "123px" }}>
                <div className={styles.container_number}>2</div>
                <div className={styles.container_participant}>No of Males</div>
              </div>
            </div>
            <div className={styles.male_female_container}>
              <div className={styles.inside_container} style={{ width: "240px" }}>
                <div className={styles.container_number}>3/6</div>
                <div className={styles.container_participant1}>Users lost interest after the first day</div>
              </div>
              <div className={styles.inside_container} style={{ width: "208px" }}>
                <div className={styles.container_number}>6/6</div>
                <div className={styles.container_participant1}>Were interested on the first day</div>
              </div>
            </div>
            <div className={styles.male_female_container}>
              <div className={styles.inside_container} style={{ width: "240px" }}>
                <div className={styles.container_number}>1/6</div>
                <div className={styles.container_participant1} style={{ width: '99px' }}>User found it boring</div>
              </div>
              <div className={styles.inside_container} style={{ width: "208px" }}>
                <div className={styles.container_number}>1/6</div>
                <div className={styles.container_participant1}>User wanted to pick their own recipe</div>
              </div>
            </div>
            <div className={styles.male_female_container}>
              <div className={styles.inside_container} style={{ width: "240px" }}>
                <span className={styles.inside_footer_name}>“Logging food everyday is tough, it's boring to do” </span>
              </div>
              <div className={styles.inside_container} style={{ width: "288px" }}>
                <span className={styles.inside_footer_name}>“If I could change the recipe that I have unlocked”</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionHookedImageContainer} data-aos="fade-up">
          <img src={circleGroupImage} alt="Demo" className={styles.inisight2Image} />
        </div>
      </section>

      <section className={`container-data ${styles.section_margin_top} ${styles.sectionDesignContainer}`}>
        <div className={styles.screenUiContainer} data-aos="fade-up">
          <span className={styles.sectionHookedTitle}>Usability testing</span>
          <div className={styles.usabilityUiContent}>
            <div className={styles.usabilitySectionText}>
              I conducted moderated usability tests with <span className={styles.five_yr}>five users</span> to identify interaction gaps and problem areas within the app. The process yielded valuable insights into certain features, leading to design iterations informed by user feedback.
            </div>
          </div>
        </div>
        <div className={styles.usabilityImageContainer} data-aos="fade-up">
          <img src={usability} alt="Demo" className={styles.section4Image2} />
        </div>
      </section>

      <section className={`container-data ${styles.section_margin_top} ${styles.sectionDesignContainer}`}>
        <div className={styles.screenUiContainer} data-aos="fade-up">
          <span className={styles.sectionHookedTitle}>Usability testing insights</span>
          <div className={styles.screenUiContent}>
            <div className={styles.sectionHookedText}>
              I conducted moderated usability tests with five users to identify interaction gaps and problem areas within the app. The process yielded valuable insights into certain features, leading to design iterations informed by user feedback.
            </div>
          </div>
        </div>
        <div className={styles.sectionHookedImageContainer} data-aos="fade-up">
          <img src={UsabilityTesting} alt="Demo" className={styles.section4Image3} />
        </div>
      </section>

      <section className={`${styles.section_margin_top} ${styles.takeWaysContainer}`}>
        <div className={`container-data ${styles.takeWaysContainer1}`}>
          <span ref={sectionRefs[5]} className={styles.section4SolutionTitle}>Takeways</span>
          <div className={styles.takeWaysContainer2}>
            <div className={styles.takeWaysInnerContainer} data-aos="fade-up">
              <div className={styles.takeWaysContent}>
                <div className={styles.takeAwayPoint}>
                  <div className={styles.section_circle}>•</div>
                  <div>Employing a structured approach, such as the Double Diamond Method and the 5 Elements of UX Design, facilitates the development of a well-rounded, user-centric product."</div>
                </div>
                <div className={styles.takeAwayPoint}>
                  <div className={styles.section_circle}>•</div>
                  <div>Prioritizing features using methods such as the KANO model and the MoSCoW methodology ensures the development of a streamlined and effective product that addresses users' most critical needs.</div>
                </div>
                <div className={styles.takeAwayPoint}>
                  <div className={styles.section_circle}>•</div>
                  <div>Conducting comprehensive usability testing and pretotyping during the design process is crucial for refining the prototype and ensuring its functionality and user-friendliness.</div>
                </div>
              </div>
            </div>
            <div className={styles.takewayImageContainer} data-aos="fade-up">
              <img src={Groupp} alt="Demo" className={styles.takewayImage} />
            </div>
          </div>
        </div>
      </section>
      <SectionNavigation sectionButtionRef={sectionButtionRef} arrayToMap={arrayToMap} sectionRefs={sectionRefs} topRef={topRef} />
      <NextCase sectionButtionRef={sectionButtionRef} caseName='Hair Food' redirectUrl="/hair-food" imageUrl={HairomaMainPNG} />
    </>
  );
};

export default CalorieWise;
