import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

function useWindowScroll() {
  const location = useLocation();
  const { pathname } = location;
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (
        (scrollPosition >= 40 && scrollPosition <= 60) ||
        scrollPosition === 0
      ) {
        setCurrentScrollPosition(Math.floor(window.scrollY));
      }
    };

    window.addEventListener("scroll", handleScroll);

    function scrollTop() {
      window.scrollTo(0, 0);
    }
    scrollTop();
    // Reinitialize Lenis on each page load
    window.addEventListener("load", scrollTop);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", scrollTop);
    };
  }, [pathname]);

  return { currentScrollPosition };
}

export default useWindowScroll;
