import styles from './index.module.css'
import { projects } from '../../utils/constants';
import Footer from '../Footer';
import gsap from 'gsap';
import MyJourney from '../MyJourney';
import { useNavigate } from 'react-router-dom';
import AnimatedButton from '../AnimatedButton';
import { useEffect, useRef } from 'react';
import mainLogo from '../../assets/new-images/logo_main.png';
import Header from '../Header';
import { Parallax } from '../Parallex';

function Home() {
  const containerRef = useRef(null);
  const logoRef = useRef<any>(null);
  const navigate = useNavigate();
  const bgRef = useRef(null);
  function hideProjectDesc(elementId: any, projectDesc: any, tool: any, projectImage: any) {
    const element = document.querySelector(elementId) as HTMLElement;
    gsap.to(element, {
      y: "-5%",
      height: '0px',
      duration: 0.4,
      onComplete: () => {
        // Then hide the element by setting display to 'none'
        element.style.display = 'none';
      }
    });
    gsap.fromTo(projectDesc, { y: "0%" }, { y: "-3%", duration: 0.5 });
    gsap.fromTo(tool, { y: "0%" }, { y: "-25%", duration: 0.4 });
    gsap.fromTo(projectImage, { y: "0%" }, { y: "-25%", duration: 0.4 });

  };
  // Function to hide the div (animate from top to bottom)
  function showProjectDesc(elementId: any, projectDesc: any, tool: any, projectImage: any) {
    const element = document.querySelector(elementId) as HTMLElement;
    // Show the element first
    element.style.display = 'flex';
    gsap.fromTo(element,
      { y: "0%", height: '0px' },
      { y: "0%", height: '360px', duration: 0.4 }
    );
    gsap.fromTo(projectDesc, { y: "-3%" }, { y: "0%", duration: 0.5 });
    gsap.fromTo(tool, { y: "-25%" }, { y: "0%", duration: 0.4 });
    gsap.fromTo(projectImage, { y: "-25%" }, { y: "0%", duration: 0.4 });
  }
  const showHideProject = (project: any, tag: any) => {
    const parentEle = '#project' + project.id;
    const projectDesc = '#project_desc' + project.id;
    const projectTool = '#tool' + project.id;
    const projectImage = '#project_image' + project.id;
    if (tag === 'show') {
      project.expand = false;
      hideProjectDesc(parentEle, projectDesc, projectTool, projectImage);
    }
    if (tag === 'hide' && !project.expand) {
      project.expand = true;
      showProjectDesc(parentEle, projectDesc, projectTool, projectImage);
    }
  };
  const redirectTo = (item: any) => {
    if (item.isExternal) {
      window.open(item.URL);
    } else {
      navigate(item.URL)
    }
  }

  useEffect(() => {
    let tl = gsap.timeline({ ease: "SlowMo.easeOut" });
    tl.to('#create_home_content', { y: '0%', duration: 1, delay: -0.6 });
    gsap.fromTo("#main_logo", { y: '100%' }, {
      y: '-0%', rotationY: 360, duration: 1.5, onComplete: () => {
        gsap.to(logoRef.current, {
          y: "8%", // Move the element to the bottom
          x: "8%",
          duration: 2.5, // Animation duration
          repeat: -1, // Repeat infinitely
          yoyo: true, // Go back to the top after reaching the bottom
          ease: "power1.inOut", // Smooth easing
        });
      }
    });
  }, [])
  return (
    <section ref={containerRef}>
      <Header />
      {/* Name and logo */}
      <section className={`container-data ${styles.homepage_bg_image}`} ref={bgRef}>
        <div id='hide' className={`hide_overflow`}>
          <div id='create_home_content'>
            <div className={styles.homegape_color}>
              <div>
                <span className={styles.name}>👋🏻 Hello! I’m &nbsp;</span>
                <span className={styles.owner_name}>Daiwat Thavai</span>
              </div>
              <div className={`${styles.name}`}>
                <div><span>a Product Designer running around</span></div>
                <div><span>deadlines and tracking my caffeine intake!</span></div>
              </div>
            </div>
            <div className={`${styles.msg_container} ${styles.msg_desc}`}>
              <div className={`${styles.home_work_ex}`}>
                <span>Previously worked as</span>
              </div>
              <div>
                <span className={styles.msg_desc_color}>Product Design Intern - </span>
                <span className={styles.msg_desc_company}>Verde Finance</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.logo_main} ref={logoRef}>
          <img id='main_logo' src={mainLogo} alt='main logo' />
        </div>
      </section>
      <section className={`flex container-data jc-space-between ${styles.homepage_exe_color} ${styles.margin_top}`}>
        <div className={styles.homepage_exe_yr}>
          <div className={styles.hompepage_exp_text} data-aos="fade-up">
            <div data-aos="fade-up">With 2+ years of experience, I blend aesthetics and</div>
            <div data-aos="fade-up">problem-solving, creating inclusive and impactful</div>
            <div data-aos="fade-up">experiences. Excited to collaborate on meaningful solutions</div>
          </div>
        </div>
        <div className={styles.home_exe_yr_rignt} data-aos="fade-up">
          <p data-aos="fade-up">My enthusiasm for enhancing user experiences,</p>
          <p data-aos="fade-up">coupled with my expertise in design and interaction,</p>
          <p data-aos="fade-up">positions me as a valuable member of a dream team</p>
          <p data-aos="fade-up">dedicated to elevating user experiences collectively.</p>
        </div>
      </section>
      {/* about me */}
      <section className={styles.homepage_about}>
        <Parallax className={''} speed={0.7} id='about-me' direction='vertical' start="bottom 90%" end="top 10%" opposite={true}>
          <AnimatedButton linkTo='/about' name='About Me' id='about_me' mouseEnterTop="130%" mouseEnterToScale={5} linkClassName='about_common_class about_me_button' circleClassName='about_common_circle_class about_me_button_circle' />
        </Parallax>
      </section>
      {/* My Work */}
      <section className='container-data'>
        <div className={styles.homepage_my_work_title}>
          <span>MY WORK</span>
        </div>
        <div className={styles.homepage_work_list_container} >
          {projects.map((project, idx) => {
            return (
              <a onClick={() => redirectTo(project)} key={project.name} className='cursor' onMouseLeave={() => showHideProject(project, 'show')}>
                <div className={`flex jc-space-between ${styles.hompage_project_name}`}>
                  <div className={styles.hompage_project_title}>
                    <span onMouseEnter={() => showHideProject(project, 'hide')}>{project.name}</span>
                  </div>
                  <div className={styles.hompage_project_domain}>
                    <span onMouseEnter={() => showHideProject(project, 'hide')}>{project.domain}</span>
                  </div>
                </div>
                <div className={`flex jc-space-between ${styles.project_desc_container}`} id={'project' + project.id}>
                  <div>
                    <div id={'project_desc' + project.id} className={idx % 2 === 0 ? styles.homepage_project_desc : styles.homepage_project_desc2}>
                      <span>{project.desc}</span>
                    </div>
                    <div id={'tool' + project.id} className={styles.homepage_tools_container}>
                      {project.tools.map((tool) => {
                        return (
                          <div key={tool} className={styles.homepage_tool_title}>{tool}</div>
                        )
                      })}
                    </div>
                  </div>
                  <div className={`${project.bgColorClass} ${styles.homepage_project_bg_container}`}>
                    <div id={'project_image' + project.id}>
                      <img src={project.imageURL} alt={project.name} className={styles.home_project_img}></img>
                    </div>
                  </div>
                  <div
                    id={'project_work' + project.id}
                    className={styles.circleButton}
                  >
                    <div className={styles.circle_view}> View </div>
                  </div>
                </div>
                <div className={`${idx !== (projects.length - 1) ? project.show ? styles.homepage_project_border_pd : styles.homepage_project_border : ''}`}></div>
              </a>
            )
          })}
        </div>
      </section>
      {/* My journey */}
      <MyJourney />
      {/* Have a project in mind */}
      <section className={styles.homepage_have_a_project_conatiner}>
        <div className={`container-data ${styles.homepage_have_a_project_bg}`}>
          <div className={styles.homepage_have_a_project_container2}>
            <div className={styles.homepage_my_work_title} data-aos="fade-up">
              <span>HAVE A PROJECT IN MIND?</span>
            </div>
            <div className={styles.homepage_have_a_project_desc}>
              <div data-aos="fade-up"><span>If you want to chat about a project — email me at <span className="homepage_have_a_project_email"><a title='daiwat.work@gmail.com' href='mailto:daiwat.work@gmail.com'>daiwat.work@gmail.com</a></span><br></br></span></div>
              <div data-aos="fade-up">
                <span data-aos="fade-up">
                  I can help designing a website, designing a new product, improving an existing part of your product, building a strong design system, building websites in Webflow or Framer
                </span>
              </div>
              <br></br>
              <div data-aos="fade-up"><span>Currently —</span> <span className="available" data-aos="fade-up">available</span><span data-aos="fade-up"> and open to onsite, hybrid, and remote-friendly opportunities, with full flexibility for relocation.</span></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </section>
  )
}

export default Home