import { gsap } from "gsap";
import { useEffect, useRef } from "react";
import { useWindowSize } from "@studio-freight/hamo";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function Parallax({
  className,
  children,
  speed = 0.6,
  id = "parallax",
  direction = "horizontal",
  start = "bottom 90%",
  end = "top 10%",
  opposite = false,
}) {
  const trigger = useRef();
  const target = useRef();
  const timeline = useRef();
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const y = opposite
      ? -(windowWidth * speed * 0.1)
      : windowWidth * speed * 0.1;
    let setDirection = "x";
    if (direction === "vertical") {
      setDirection = "y";
    }
    const setY = gsap.quickSetter(target.current, setDirection, "px");

    timeline.current = gsap.timeline({
      scrollTrigger: {
        id: id,
        trigger: trigger.current,
        scrub: true,
        start: start,
        // markers:true,
        end: end,
        onUpdate: (e) => {
          setY(e.progress * y);
        },
      },
    });

    return () => {
      timeline?.current?.kill();
    };
  }, [id, speed, windowWidth]);

  return (
    <div ref={trigger} className={className}>
      <div ref={target}>{children}</div>
    </div>
  );
}
