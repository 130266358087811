import React from 'react';
import styles from './index.module.css';
import { tools } from '../../utils/constants';

function Tool({ tool }: any) {
  return (
    <div data-aos="fade-up" className={styles.tool_container}>
      <div data-aos="fade-up" className={styles.tool_logo}>
        <img data-aos="fade-up" className={styles.tool_logo_size} src={tool.imageURL} alt={tool.name}></img>
      </div>
      <div data-aos="fade-up" className={styles.tool_desc_container}>
        <div data-aos="fade-up" className={styles.tool_name}>
          {tool.name}
        </div>
        <div data-aos="fade-up" className={styles.tool_type}>
          {tool.type}
        </div>
      </div>
    </div>
  )
}

function Tools() {
  return (
    <section data-aos="fade-up" className={styles.tools_container}>
      <div data-aos="fade-up" className={styles.tool_title}>
        TOOLS
      </div>
      <div data-aos="fade-up" className={styles.tool_container_all}>
        {tools.map((tool) => {
          return (
            <Tool key={tool.name} tool={tool} />
          )
        })}
      </div>
    </section>
  )
}

export default Tools