import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import RootLayout from '../RootLayout';
import Home from '../Home';
import About from '../About';
import CalorieWise from '../Projects/CalorieWise';
import Tju from '../Projects/TJU';
import HairFood from '../Projects/HairFood';
import Work from '../Projects/Work';
import NotFond from '../NotFound';

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route index element={<Home />}></Route>
      <Route path='/work' element={<Work />}></Route>
      <Route path='/about' element={<About />}></Route>
      <Route path='/calorie-wise' element={<CalorieWise />}></Route>
      <Route path='/improving-the-course-registration-experience' element={<Tju />}></Route>
      <Route path='/hair-food' element={<HairFood />}></Route>
      <Route path='/global-bridge' element={<h1>Global Bridge</h1>}></Route>
      <Route path='*' element={<NotFond />}></Route>
    </Route>
  )
)

export default Router