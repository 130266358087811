import React, { useEffect } from 'react'
import styles from './index.module.css'
import GetInTouch from '../GetInTouch';

import rightArrowSvg from '../../assets/svgs/right-arrow.svg';
import MyJourney from '../MyJourney';
import Tools from '../Tools';
import DaiwatThavaiImage from '../../assets/imgages/daiwat-thavai.jpeg';
import photography from '../../assets/imgages/photography.png';
import cosplay from '../../assets/imgages/cosplay.png';
import footplay from '../../assets/imgages/footplay.jpg';
import travelling from '../../assets/imgages/travelling.png';
import restAndCafe1 from '../../assets/imgages/rest_and_cafe.png';
import restAndCafe2 from '../../assets/imgages/rest_and_cafe_2.jpg';
import Footer from '../Footer';
import gsap from 'gsap';
import Header from '../Header';
import { resumeLink } from '../../utils/constants';

export default function About() {
  useEffect(() => {
    let tl = gsap.timeline({ ease: "SlowMo.easeOut" });
    tl.fromTo('#hide', { opacity: 0 }, {
      opacity: 1, duration: 0.5, onComplete: () => {
        tl.to('#create', { y: '0%', duration: 0.5 });
      }
    });
  }, [])
  return (
    <section>
      {/* <Loader /> */}
      <Header />
      <section className={styles.about_container}>
        <div id='hide'>
          <div className={`${styles.about_info} hide_overflow`}>
            <div><span id='create'>Designer based in</span></div>
            <div><span id='create'>Philadelphia</span></div>
          </div>
        </div>
        <a href={resumeLink} target='_blank'>
          <GetInTouch showParallex={false} name='Download Resume' borderClass={styles.border_download_resume} id="get_in_touch" />
        </a>
        {/* about me */}
        <div className={`container-data ${styles.about_me_container}`}>
          <div className={styles.about_me_container_left}>
            <img src={rightArrowSvg} alt="right arrow" />
          </div>
          <div className={styles.about_me_container_right}>
            <div className={styles.right}>
              <div className={styles.right_about_me}>
                <div className={styles.about_me_title} >
                  ABOUT ME
                </div>
                <div className={styles.about_me_desc}>
                  <p data-aos="fade-up">I'm Daiwat Thavai, a product</p>
                  <p data-aos="fade-up">designer passionate about user</p>
                  <p data-aos="fade-up">experience and an instinct</p>
                  <p data-aos="fade-up">for balancing creativity with a</p>
                  <p data-aos="fade-up">practical, data-driven approach.</p>
                </div>
                <div className={styles.about_explore}>
                  <p data-aos="fade-up">
                    Always exploring
                    <span className={styles.animate_dot1}>.</span>
                    <span className={styles.animate_dot2}>.</span>
                    <span className={styles.animate_dot3}>.</span>
                  </p>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" className={styles.left}>
              <img data-aos="fade-up" src={DaiwatThavaiImage} alt='Daiwat Thavai'></img>
            </div>
          </div>
        </div>
      </section>
      {/* I can help you with.. */}
      <div className={`container-data ${styles.help_conatiner}`}>
        <h1 data-aos="fade-up">I can help you with..</h1>
        <div className={styles.help_section_container} data-aos="fade-up">
          <div>
            <div className={styles.help_section_title}>01</div>
            <div className={styles.help_section_type}>Design Strategy</div>
            <div className={styles.help_section_category}>
              <p>Vision & Planning</p>
              <p>User-Focused Approaches</p>
              <p>Market Research</p>
            </div>
          </div>
          <div>
            <div className={styles.help_section_title}>02</div>
            <div className={styles.help_section_type}>User Research</div>
            <div className={styles.help_section_category}>
              <p>Interviews & Surveys</p>
              <p>User Insights</p>
              <p>Research Methods</p>
            </div>
          </div>
          <div>
            <div className={styles.help_section_title}>03</div>
            <div className={styles.help_section_type}>Interactive Design</div>
            <div className={styles.help_section_category}>
              <p>Visual Design</p>
              <p>Design Systems</p>
              <p>Prototyping</p>
            </div>
          </div>
        </div>
      </div>
      {/* my journey */}
      <div className={styles.about_my_journey_container}>
        <MyJourney />
      </div>
      {/* tools */}
      <div className={`container-data ${styles.about_tools_container}`}>
        <Tools />
      </div>
      {/* Area of interest */}
      <div className={`container-data ${styles.area_of_interest}`} data-aos="fade-up">
        <div data-aos="fade-up" className={styles.area_of_interest_title}>
          AREA OF INTEREST
        </div>
        <div data-aos="fade-up" className={styles.area_of_interest_container}>
          {/* div left */}
          <div className={styles.area_left}>
            <div data-aos="fade-up" className={styles.area_left_div1}>
              <img data-aos="fade-up" src={photography} alt='Photography'></img>
              <label data-aos="fade-up" className={styles.area_lebel}>Photography</label>
            </div>
            <div data-aos="fade-up" className={styles.area_left_div2}>
              <img data-aos="fade-up" src={cosplay} alt='Cosplay'></img>
              <label data-aos="fade-up" className={styles.area_lebel}>Cosplay</label>
            </div>
          </div>
          {/* div middle */}
          <div data-aos="fade-up" className={styles.area_middle}>
            <div data-aos="fade-up" className={styles.area_left_div1}>
              <label data-aos="fade-up" className={styles.area_lebel}>Football</label>
              <img data-aos="fade-up" src={footplay} alt='Footplay'></img>
            </div>
            <div data-aos="fade-up" className={styles.area_left_div2}>
              <img data-aos="fade-up" src={travelling} alt='Travelling'></img>
              <label data-aos="fade-up" className={styles.area_lebel}>Travelling.</label>
            </div>
          </div>
          {/* div right */}
          <div data-aos="fade-up" className={styles.area_right}>
            <div className={styles.area_left_div1}>
              <img data-aos="fade-up" src={restAndCafe1} alt='Footplay'></img>
            </div>
            <div data-aos="fade-up" className={styles.area_left_div2}>
              <img data-aos="fade-up" src={restAndCafe2} alt='Travelling'></img>
            </div>
            <label data-aos="fade-up" className={styles.area_lebel}>Exploring new Restaurants and cafes</label>
          </div>
        </div>
      </div>
      {/* have a project in a mind */}
      <div className={styles.about_project_mind_container_parent}>
        <div className={`container-data ${styles.about_project_mind_container}`}>
          <div className={styles.about_project_title} data-aos="fade-up">
            Have a project in mind?
          </div>
          <div className={styles.project_title_underline} data-aos="fade-up"></div>
          <div className={styles.project_desc} data-aos="fade-up">
            <span className={styles.project_desc_span1} data-aos="fade-up">If you want to chat about a project — email me at</span> <span className="homepage_have_a_project_email" data-aos="fade-up"><a title='daiwat.work@gmail.com' href='mailto:daiwat.work@gmail.com'>daiwat.work@gmail.com</a></span><br></br>
            <span className={styles.project_desc_span1} data-aos="fade-up">I can help designing a website, designing a new product, improving an existing part of your product, building a strong design system, building websites in Webflow or Framer</span>
            <br></br>
            <br></br>
            <span className={styles.project_desc_span1} data-aos="fade-up">Currently —</span> <span className="available" data-aos="fade-up">available</span> <span className={styles.project_desc_span1} data-aos="fade-up"> and open to onsite, hybrid, and remote-friendly opportunities, with full flexibility for relocation.</span>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  )
}
