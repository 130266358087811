import React from 'react';
import styles from './index.module.css';

function FooterInfo() {
  return (
    <div className={styles.footer_info_container}>
      <div className={styles.footer_info_data}>
        <div className={styles.footer_info_name_copy}>
          {/* <span className={styles.footer_info_name_yr}>Daiwat Thavai</span><span className={styles.footer_info_name_copy}> ©</span> <span className={styles.footer_info_name_yr}>2024</span> */}
          <div className={styles.footer_info_version}>
            <div className={styles.footer_info_version_title} >
              Version
            </div>
            <div>
              <span className={styles.footer_info_name_yr}>Daiwat Thavai</span><span className={styles.footer_info_name_copy}> ©</span> <span className={styles.footer_info_name_yr}>2024</span>
            </div>
          </div>
        </div>
        <div className={styles.footer_location}>
          <div className={styles.footer_info_version}>
            <div className={styles.footer_info_version_title}>
              Location
            </div>
            <div>
              <span className={styles.footer_location_color}>Philadelphia</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer_social_media_container}>
        <div className={styles.footer_info_version_title}>
          Socials
        </div>
        <div className={styles.social_container_links}>
          <a href="https://www.linkedin.com/in/daiwat-thavai-5ab435182/" className={styles.footer_linkedIn} target='_blank'>
            LinkedIn
          </a>
          <a href="https://www.behance.net/daiwatthawai" className={styles.footer_linkedIn} target='_blank'>
            Behance
          </a>
        </div>
      </div>
    </div>
  )
}

export default FooterInfo